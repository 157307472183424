<template>
    <div class="about">
        <div class="about__inner">
            <div class="about__content about-content">
                <h1 class="about-content__heading heading">О НАС</h1>

                <p class="about-content__text">Мы официальный дистрибьютор алкогольной продукции, произведенной в республике Беларусь, на территории республики Крым и г. Севастополь.</p>

                <p class="about-content__text">Сотрудничаем с заводами-производителями, производящими флагманские линейки алкоголя.</p>

                <p class="about-content__text">Реализуем алкогольную и безалкогольную продукцию по оптовым ценам.</p>

                <button 
                    @click="redirectToCatalog"
                    type="button" 
                    class="about-content__btn btn">Перейти в каталог</button>
            </div>

            <div class="about__slider-wrapper">
                <swiper
                    :slides-per-view="1"
                    :space-between="20"
                    :modules="modules"
                    :navigation="{
                                prevEl: '.swiper-button-prevv',
                                nextEl: '.swiper-button-nextt', 
                            }"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    class="about-slider about__slider"
                    >
                    
                    <swiper-slide class="about-slider__slide">
                        <img src="../images/about-slider/1.png" class="about-slider__img">
                    </swiper-slide>

                    <swiper-slide class="about-slider__slide">
                        <img src="../images/about-slider/2.png" class="about-slider__img">
                    </swiper-slide>

                    <swiper-slide class="about-slider__slide">
                        <img src="../images/about-slider/3.png" class="about-slider__img">
                    </swiper-slide>
                </swiper>

                <div class="about__slider-controlls">
                    <div class="swiper-button-prevv"></div>
                    <div class="swiper-button-nextt"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { ref } from 'vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    setup() {
        const onSwiper = (swiper) => {

        };
        const onSlideChange = () => {

        };
        const prev = ref('swiper-button-prevv');
        const next = ref('swiper-button-nextt');
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
            prev,
            next,
        };
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        redirectToCatalog(){
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .about__inner{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .about-content__heading{
        margin-bottom: 40px;
    }
    .about-content__text{
        font-size: 24px;
        color: #360A04;
        margin-bottom: 25px;
        font-weight: 600;
    }
    .about-content__btn{
        display: inline-block;
        margin-top: 20px;
    }
    /* slider */
    
    .about__slider-wrapper{
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .about__slider{
        width: 100%;
    }
    .about-slider__slide{
        display: flex;
    }
    .about__slider-controlls{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 250px;
    }
    .swiper-button-prevv{
        height: 80px;
        width: 80px;
        background: url(../images/icons/arrow-ico.svg) no-repeat;
        background-position: center;
        border: 1px solid #360A04;
        border-radius: 50%;
        cursor: pointer;
        transition: all linear .2s;
    }
    .swiper-button-prevv:hover{
        opacity: .5;
    }
    .swiper-button-nextt{
       height: 80px;
       width: 80px;
       background: url(../images/icons/arrow-ico.svg) no-repeat;
       transform: rotate(180deg);
       background-position: center;
       border: 1px solid #360A04;
       border-radius: 50%;
       cursor: pointer;
       transition: all linear .2s;
    }
    .swiper-button-nextt:hover{
        opacity: .5;
    }
    @media (max-width: 992px) {
        .about__slider-wrapper{
            width: 50%;
        }
        .about-slider__img{
            width: 100%;
        }
    }
    @media (max-width: 768px){
        .about-content__heading{
            font-size: 36px;
            margin-bottom: 30px;
        }
        .about-content__text{
            font-size: 16px;
            margin-bottom: 15px;
        }
        .about-content__btn{
            font-size: 14px;
            padding: 10px 20px;
        }
    }
    @media (max-width: 600px){
        .about__inner{
            flex-direction: column;
        }
        .about__content{
            margin-bottom: 30px;
        }
        .about__slider-wrapper{
            width: 100%;
        }
        .about__slider-controlls{
            max-width: 120px;
        }
        .swiper-button-nextt{
            height: 40px;
            width: 40px;
            background-size: 50%;
        }
        .swiper-button-prevv{
            height: 40px;
            width: 40px;
            background-size: 50%;
        }
    }
</style>