<template>
    <div class="footer">
        <div class="footer__inner">
            <div class="footer__wrapper">
                <div class="footer__col-wrapper">
                    <div class="footer__col footer__col-1 footer-col-1">
                        <img src="../images/logo-white.svg" class="footer__logo">
                        <router-link to="/oferta" class="footer-col-1__text">Публичная оферта</router-link>
                        <router-link to="/confidential" class="footer-col-1__text">Политика конфиденциальности</router-link> 
                    </div>

                    <div class="footer__col footer__col-2 footer-categories">
                        <router-link :to="{path: '/', hash: '#about'}"  class="footer-categories__item footer__text">О нас</router-link>
                        
                        <router-link :to="{path: '/', hash: '#partners'}" class="footer-categories__item footer__text">Партнеры</router-link>

                        <router-link to="/catalog" class="footer-categories__item footer__text">Продукция</router-link>

                        <router-link :to="{path: '/', hash: '#form'}" class="footer-categories__item footer__text">Сотрудничество</router-link>
                    </div>
                    <div class="footer__col footer__col-3 footer-col-3">
                        <h4 class="footer-col-3__heading footer-col-3__heading">Контакты:</h4>
                        <p class="footer-col-3__tel"><a href="tel:+79122044433" class="footer-col-3__tel-link">+7(912)204-44-33</a> Оптовые продажи в Крыму</p>
                        <p class="footer-col-3__tel footer-col-3__tel_mob"><a href="tel:+79783029271" class="footer-col-3__tel-link">+7(978)302-92-71</a> Оптовые продажи</p>
                        <div class="footer-col-3__social footer-social">
                            <button 
                                class="footer-col-3__btn btn" @click="openConsultation">Заказать звонок</button>
                            <a href="#" class="footer-social__link footer-social__tg"></a>
                            <!-- <a href="#" class="footer-social__link footer-social__call"></a> -->
                            
                        </div>
                        <router-link 
                            to="/oferta" 
                            class="footer-col-1__text footer-col-1__text-mob">Публичная оферта</router-link>

                        <router-link 
                            to="/confidential"
                            class="footer-col-1__text footer-col-1__text-mob footer-col-1__text-mob_confidential">Политика конфиденциальности</router-link>
                </div>

                <div class="footer__col footer__col-4 footer-col-4">
                    <p class="footer-col-4__text">ООО “РСГ”</p>
                    <a href="mailto:info@alcoby.ru" class="footer-col-4__email">info@alcoby.ru</a>
                    <p class="footer-col-4__text">ИНН 7721485139</p>
                </div>
                </div>

                
            </div>
            <p class="footer-col-3__created">Сайт разработан агенством <a href="https://vsegda-digital.ru/" class="footer-col-3__created-link">Vsegda</a></p>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isNeedConsultation: false
        }
    },
    methods: {
        openConsultation(){
            this.$router.push({path: '/', hash: '#form'})
        },
        closeConsultation(){
            this.isNeedConsultation = false
        },
    },
}
</script>

<style scoped>
    .footer__inner{
        position: relative;
        padding: 160px 10px 60px 10px;
        max-width: 1600px;
        margin: 0 auto;
    }
    .footer__wrapper{
        display: flex;
        margin-bottom: 70px;
    }
    .footer__col-wrapper{
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .footer__col{
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 25px;
        line-height: 100%;
        margin-right: 20px;
    }
    .footer__col:last-child{
        margin-right: 0;
    }
    .footer__logo{
        max-width: 195px;
        margin-bottom: 50px;
    }
    
    .footer__col-1{
        margin-top: -90px;
    }
    .footer-col-1__text{
        line-height: 100%;
        color: #fff;
        margin-bottom: 30px;
        transition: all linear .2s;
    }
    .footer-col-1__text:last-child{
        margin-bottom: 0;
    }
    .footer-col-1__text:hover{
        color: #fff;
        text-decoration: underline;
    }
    .footer-categories__item{
        color: #fff;
        transition: all linear .2s;
        margin-bottom: 30px;
    }
    .footer-categories__item:hover{
        color: #fff;
        text-decoration: underline;
    }
    .footer__col-2{

    }
    .footer__col-3{

    }
    .footer-col-3__heading{
        line-height: 100%;
        font-weight: 600;
        color: #fff;
        margin-bottom: 25px;
    }
    .footer-col-3__email{
        color: #fff;
        margin-bottom: 30px;
    }
    .footer-col-3__btn{
        color: #fff;
        padding: 15px 30px;
        font-weight: 600;
        border-color: #fff;
        margin-right: 10px;
        transition: all linear .2s;
    }
    .footer-col-3__btn:hover{
        opacity: .5;
    }
    .footer__text{
        margin-bottom: 30px;
    }
    .footer__text:last-child{
        margin-bottom: 0;
    }
    .footer-col-3__social{
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }
    .footer-social__link{
        height: 50px;
        width: 50px;
        transition: all linear .2s;
        border: 1px solid #fff;
        border-radius: 50%;
    }
    .footer-social__link:hover{
        opacity: .5;
    }
    .footer-social__call{
        background: url(../images/icons/tel-ico.svg) no-repeat;
        background-position: center;
    }
    .footer-social__tg{
        background: url(../images/icons/tg-ico.svg) no-repeat;
        background-position: center;
    }
    .footer-col-3__created{
        font-weight: 500;
        color: #fff;
        text-align: center;
        font-size: 25px;
    }
    .footer-col-3__created-link{
        color: #fff;
        text-decoration: underline;
    }
    .footer-col-3__created-link:hover{
        text-decoration: none;
    }
    .footer-col-1__text-mob{
        display: none;
    }
    .footer-col-3__heading-mob{
        display: none;
    }
    .footer-col-3__tel{
        margin-bottom: 15px;
        font-size: 20px;
    }
    .footer-col-3__tel-link{
        color: #fff;
        font-weight: 600;
    }
    .footer-col-3__tel-link:hover{
        text-decoration: underline;
    }
    .footer-col-4__text{
        margin-bottom: 15px;
    }
    .footer-col-4__email{
        color: #fff;
        margin-bottom: 15px;
    }
    .footer-col-4__email:hover{
        text-decoration: underline;
    }
    @media(max-width: 992px){
        .footer__col-wrapper{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
        .footer__wrapper{
            flex-direction: column;
        }
        .footer__col-2{
            margin-top: 30px;
        }
        .footer__col-3{
            margin-left: 0;
            align-items: flex-start;
            margin-top: 40px;
        }
    }
    @media (max-width: 600px){
        .footer__inner{
            padding-top: 60px;
            padding-bottom: 60px;
        }
        .footer__logo{
            margin-bottom: 0;
        }
        .footer__wrapper{
            position: relative;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: 20px;
            margin-bottom: 95px;
        }
        .footer__text{
            font-size: 16px;
            margin-bottom: 25px;
        }
        .footer__col-wrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }
        .footer__col{
            margin-right: 0;
        }
        .footer__col-1{
            margin-top: 0;
        }
        .footer-col-3__btn{
            font-size: 16px;
            padding: 10px 25px;
        }
        .footer-col-1__text{
            display: none;
        }
        .footer-col-1__text-mob{
            display: block;
        }
        .footer-col-3__heading{
            display: none;
        }
        .footer-col-3__heading-mob{
            display: block;
            margin-bottom: 0;
            font-size: 16px;
        }
        .footer__col-2{
            margin-top: 0;
        }
        .footer__col-3{
            justify-content: flex-end;
            flex-direction: column-reverse;
            margin-top: 0;
            margin-bottom: 0;
        }
        .footer__text_pe{
            margin-top: 30px;
            margin-bottom: 0;
        }
        .footer-col-3__social{
            margin-bottom: 25px;
        }
        .footer-col-1__text-mob{
            position: absolute;
            bottom: -50px;
            left: 0;
            font-size: 16px;
        }
        .footer-col-1__text-mob_confidential{
            bottom: -60px;
        }
        .footer-col-3__btn{
            padding-top: 16px;
            padding-bottom: 16px;
            margin-left: 0;
        }
        .footer-col-3__created{
            font-size: 16px;
            font-weight: 700;
            text-align: start;
        }
        .footer-col-3__tel_mob{
            position: relative;
            padding-top: 25px;
        }
        .footer-col-3__tel_mob::before{
            position: absolute;
            content: "Контакты:";
            top: 0;
            left: 0;
        }
        .footer-col-3__tel{
            font-size: 16px;
            margin-bottom: 5px;
        }
        .footer-col-3__tel-link{
            margin-right: 10px;
        }
        .footer__col-4{
            position: absolute;
            right: 10px;
            top: 108px;
            font-size: 16px;
        }
    }
</style>