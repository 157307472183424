import { createRouter, createWebHistory } from 'vue-router'
import mainPage from '@/pages/Main-page'

const routes = [
  {
      path: '/',
      name: 'main',
      component: mainPage
  },
  {
      path: '/catalog',
      name: 'catalog',
      component: ()=> import('@/pages/Catalog-page')
  },
  {
      path: '/cart',
      name: 'cart',
      component: ()=> import('@/pages/Cart-page')
  },
  {
      path: '/login',
      name: 'login',
      component: ()=> import('@/pages/Login-page')
  },
  {
    path: '/admproduct',
    name: 'admproduct',
    component:()=> import('@/admin/Product-page') 
  },
  {
    component:()=> import('@/admin/Admin-main-page'),
    name: 'admin',
    path: '/admin'
  },
  {
    component:()=> import('@/pages/Deny-page'),
    name: 'deny',
    path: '/deny'
  },
  {
    component:()=> import('@/pages/Confidential-page'),
    name: 'confidential',
    path: '/confidential'
  },
  {
    path: '/success',
    name: 'success',
    component:()=> import('@/pages/Success-page') 
  },
  {
    path: '/oferta',
    name: 'oferta',
    component:()=> import('@/pages/Oferta-page') 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {el: to.hash, behavior: 'smooth'}
    } else {
        return { x: 0, y: 0 }
    }
  },
})

export default router
