<template>
    <div class="partners">
        <div class="partners__inner">
            <h1 class="partners__heading heading">ПАРТНЕРЫ</h1>
            <p class="partners__text">Алкогольная продукция ведущих флагманов алкогольной продукции Белоруссии доступна в таких то таких то местах</p>
            <div class="partners__wrapper">
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/1.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/2.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/3.svg">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/4.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/5.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/6.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/7.png">
                </div>
                <div class="partners__item">
                    <img
                        class="partners__img" 
                        src="../images/partners/8.png">
                </div>
            </div>
            <button 
                @click="redirectToCatalog"
                type="button" 
                class="partners__btn btn">Перейти в каталог</button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        redirectToCatalog(){
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .partners__inner{
        display: flex;
        flex-direction: column;
    }
    .partners__heading{
        margin-bottom: 80px;
    }
    .partners__text{
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 80px;
        color: #360A04;
        max-width: 700px;
    }
    .partners__wrapper{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 50px 90px;
        margin-bottom: 80px;
    }
    .partners__item{
        display: flex;
        justify-content: center;
    }
    .partners__btn{
        margin-left: auto;
    }
    @media (max-width: 1240px) {
        .partners__wrapper{
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (max-width: 992px){
        .partners__heading{
            margin-bottom: 30px;
        }
        .partners__wrapper{
            grid-template-columns: repeat(4, 125px);
            justify-content: space-between;
            gap: 20px;
        }
        .partners__img{
            width: 100%;
            object-fit: scale-down;
        }
    }
    @media (max-width: 600px){
        .partners__heading{
            margin-bottom: 30px;
        }
        .partners__wrapper{
            grid-template-columns: repeat(4, 1fr);
        }
        .partners__text{
            display: none;
        }
        .partners__btn{
            display: none;
        }
    }
</style>