import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
	state: {
		products: [],
		categories: [],
		manufacturers: [],
		cart: [],
		selectedCategory: 'Все',
        category: 'Все',
		selectedManufacturer: '',
		page: 1,
		totalPages: 0,
		cardsPerPage: 9,
		searchValue: '',
		orders: [],
        currentProduct: ''
	},
	getters: {
		PRODUCTS(state){
			return state.products
		},
        CURRENT_PAGE(state){
            return state.page
        },  
		CATEGORIES(state){
			return state.categories
		},
		MANUFACTURERS(state){
			return state.manufacturers
		},
		SELECTED_CATEGORY(state){
			return state.selectedCategory
		},
		CART(state){
			return state.cart
		},
		SELECTED_PRODUCT(state){
			return state.selectedProduct
		},
		SELECTED_MANUFACTURER(state){
			return state.selectedManufacturer
		},
		SELECTED_PAGE(state){
            return state.page
        },
		TOTAL_PAGES(state){
            return state.totalPages
        },
		ORDERS(state){
            return state.orders
        },
        SELECTED_PRODUCT(state){
            return state.currentProduct
        }, 
        SET_PAGE: (state, value)=>{
            state.page = value
        },
	},
	mutations: {
		SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
		REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
		SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        SET_CATEGORIES: (state, categories)=>{
            state.categories = categories
        },
		SET_DEFAULT_PAGE_NUMBER: (state)=>{
            state.page = 1
        },
		SET_CATEGORIES: (state, categories)=>{
            state.categories = categories
        },
        SET_SUBCATEGORIES: (state, subcategories)=>{
            state.manufacturers = subcategories
        },
		SET_ORDERS_TO_STATE: (state, orders)=>{
            state.orders = orders
        },
        SET_ONE_PRODUCT: (state, data)=>{
            state.currentProduct = data
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.selectedCategory = category
        },
        SET_MANUFACTURER_VALUE: (state, value)=>{
            state.selectedManufacturer = value
        },
        SET_PAGE: (state, value)=>{
            state.page = value
        },
	},
	actions: {
        GET_ONE_PRODUCT({commit}, data){
            commit('SET_ONE_PRODUCT', data)
        },
		GET_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                console.log('hi');
                return axios({
                    url: 'https://alkoby.ru:3000/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        page: this.state.page
                    }

                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }
            
            
            if(this.state.selectedCategory !=='Все'){
                return axios({
                    url: 'https://alkoby.ru:3000/api/goods/sort',
                    method: "GET",
                    params:{
                        category: this.state.selectedCategory,
                        manufacturer: this.state.selectedManufacturer,
                        page: this.state.page
                    }

                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                    commit('SET_TOTAL_PAGES', products.data.count);
                    
                })
            }
            
            return axios(`https://alkoby.ru:3000/api/goods/flowers/all`,{
                method: "GET",
                params:{
                    page: this.state.page,
                    manufacturer: this.state.selectedManufacturer,
                }
                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data.rows);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
        },
		GET_CATEGORIES({commit}){
            return axios('https://alkoby.ru:3000/api/category/all',
            {
                method: "GET",
            }).then((categories)=>{
                commit('SET_CATEGORIES', categories.data);
            })
        },
        GET_SUBCATEGORIES({commit}){
            return axios('https://alkoby.ru:3000/api/subcategory/all',
            {
                method: "GET",
            }).then((subcategories)=>{
                commit('SET_SUBCATEGORIES', subcategories.data);
            })
        },
		GET_ORDERS({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://alkoby.ru:3000/api/orders/orders-by-admin',
            {headers: {Authorization: `Bearer ${cookie[1]}`}
            }).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
                // this.orders = res.data.rows
            })
        },
        GET_MANUFACTURER_VALUE({commit}, value){
            commit('SET_MANUFACTURER_VALUE', value)
        },
		ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
		DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
		RESET_PAGE_NUMBER({commit}){
            commit('SET_DEFAULT_PAGE_NUMBER')
        },
        SELECT_CATEGORY({commit}, value){
            commit('SET_SELECTED_CATEGORY', value)
        },
        GET_PAGE_NUMBER({commit}, value){
            commit('SET_PAGE', value)
        },
	}
})
