<template>
    <div class="form">
        <div class="form__wrapper">
            <div class="form__photo">
                <img 
                    class="form__img"
                    src="../images/form/bottle.png">
            </div>
            <div class="form__inner">
                <h1 class="form__heading">Остались вопросы?</h1>
                <p class="form__text">Напишите нам! Заполните форму и мы перезвоним вам в ближайшие время.</p>
                <input 
                    v-model="state.name"
                    class="form__input input" 
                    placeholder="Ваше имя"
                    :class="{invalid: v$.name.$error}">

                <input 
                    type="tel"
                    v-model="state.tel"
                    class="form__input form__tel input" 
                    placeholder="+7(999)999-99-99"
                    v-imask="phoneNumberMask" 
                    maxlength="16"
                    :class="{invalid: v$.tel.$error}">

                <button 
                    @click="submit"
                    type="button" 
                    :class="{loading: isLoading}"
                    class="form__submit btn">Оставить заявку</button>

                <p class="form__notify">Нажимая на кнопку, вы подтверждаете, что согласны с <router-link to="/confidential" class="form__marked"> политикой конфиденциальности</router-link></p>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'
import useValidate from '@vuelidate/core'
import { required, minLength} from '@vuelidate/validators'
import { reactive } from 'vue'
import { IMaskDirective } from 'vue-imask';

export default {
    setup(){
        const state = reactive({
            name: '',
            tel: ''
        })
        const rules = {
            name: {required},
            tel: {required, minLength: minLength(16)},
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            phoneNumberMask: {
                mask: '+{7}(000)000-00-00',
                lazy: true
            },
            isLoading: false
        }
    },
    methods: {
        async submit(){
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            this.isLoading = true
            axios('https://alkoby.ru:3000/api/orders/getConsultation',{
                method: "POST",
                params: {
                    name: this.state.name,
                    tel: this.state.tel
                    }
            }).then((res)=>{
                alert('Заявка успешно отправлена! С вами свяжутся в ближайшее время')
                this.isLoading = false
            })
        }
    },
    directives: {
        imask: IMaskDirective
    }
}
</script>

<style scoped>
    .form__wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form__photo{
        display: flex;
        margin-right: 30px;
    }
    .form__inner{
        display: flex;
        flex-direction: column;
        max-width: 640px;
    }
    .form__heading{
        text-transform: uppercase;
        font-size: 55px;
        color: #360A04;
        margin-bottom: 35px;
        font-weight: 900;
    }
    .form__text{
        font-size: 25px;
        font-weight: 600;
        margin-bottom: 40px;
    }
    .form__input{
        margin-bottom: 15px;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .form__submit{
        text-transform: uppercase;
        font-size: 20px;
        margin-top: 15px;
        padding: 15px 35px;
        align-self: flex-start;
        margin-bottom: 29px;
        transition: all linear .2s;
    }
    .form__submit:hover{
        color: #fff;
        background-color: #360A04;
    }
    .form__notify{
        color: #ABABAB;
        font-size: 15px;
        max-width: 370px;
    }
    .form__marked{
        color: #ABABAB;
        text-decoration: underline;
    }
    .form__img{
        width: 100%;
    }
    @media (max-width: 992px) {
        .form__photo{
            display: none;
        }
        .form__inner{
            max-width: none;
            width: 100%;
        }
    }
    @media (max-width: 600px){
        .form__heading{
            font-size: 33px;
            margin-bottom: 20px;
        }
        .form__text{
            font-size: 16px;
            max-width: 350px;
            margin-bottom: 15px;
        }
        .form__input{
            margin-bottom: 10px;
            font-size: 14px;
        }
        .form__submit{
            font-size: 14px;
            margin-bottom: 15px;
        }
        .form__notify{
            font-size: 13px;
            max-width: 335px;
        }
    }
</style>