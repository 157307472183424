<template>
  <div class="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    mounted(){
        let age = localStorage.getItem('isAdult')
        if (age != 'adult') {
            this.$router.push('/')
        }
    }
}
    
</script>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    @font-face {
        font-family: 'Bebas Neue';
        src: local('Bebas Neue'), url('./fonts/Bebas Neue.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
	body{
		font-family: "Raleway", sans-serif;
	}
    a, button{
        font-family: "Raleway", sans-serif;
    }
    a{
        text-decoration: none;
    }
    button{
        background: none;
        border: none;
        cursor: pointer;
    }
    .container{
        max-width: 1270px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .heading{
        font-size: 60px;
        color: #360A04;
        text-transform: uppercase;
        font-weight: 900;
    }
    .btn{
        color: #360A04;
        padding: 25px 30px;
        font-size: 22px;
        line-height: 100%;
        border: 1px solid #360A04;
        font-weight: 600;
        border-radius: 85px;
        transition: all linear .2s;
    }
    .btn:hover{
        color: #fff;
        background-color: #360A04;
    }
    .input{
        font-size: 18px;
        font-weight: 600;
        border: 1px solid #360A04;
        color: #360A04;
        padding: 20px 30px;
        border-radius: 30px;
    }
    .input::placeholder{
        color: #360A04;
    }
    .footer-bg{
        background-color: #300402;
    }
    /* checkbox */
    .checkbox__wrapper{
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
    }
    .checkbox__ico{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .invalid{
        border-color: red;
    }
    .loading{
        opacity: .5;
        pointer-events: none;
    }
    @media (max-width: 600px){
        .container{
            padding-left: 10px;
            padding-right: 10px;
        }
        .heading{
            font-size: 36px;
        }
        .btn{
            font-size: 14px;
        }
    }
</style>
