<template>
    <div class="main-page">
        <div class="main-page__inner">
            <Header class="main-page__header container"/>
            <Banner class="main-page__banner"/>
            <div class="container">
                <About 
                    id="about"
                    class="main-page__about"/>
                <Production class="main-page__production"/>
                <Partners 
                    id="partners"
                    class="main-page__partners"/>
                <Form 
                    id="form"
                    class="main-page__form"/>
            </div>
            <Footer 
                id="contacts"
                class="main-page__footer"/>
        </div>
        <AgeBanner
            v-if="!isAdult"
            @checkAge="checkAge"/>
    </div>
</template>

<script>
import Header from '@/components/Header'
import Banner from '@/components/Banner'
import About from '@/components/About'
import Production from '@/components/Production'
import Partners from '@/components/Partners'
import Form from '@/components/Form'
import Footer from '@/components/Footer'
import AgeBanner from '@/components/Age-banner'

export default {
    data() {
        return {
            isAdult: false
        }
    },
    components: {
        AgeBanner,
        Header,
        Banner,
        About,
        Production,
        Partners,
        Form,
        Footer
    },
    methods: {
        checkAge(bool){
            this.isAdult = bool
        }
    },
    mounted() {
        let age = localStorage.getItem('isAdult')
        if (age == 'adult') {
            this.isAdult = true
        }
    },
}
</script>

<style scoped>
    .main-page__header{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 100;
    }
    .main-page__banner{
        margin-bottom: 60px;
    }
    .main-page__about{
        margin-bottom: 120px;
    }
    .main-page__production{
        margin-bottom: 120px;
    }
    .main-page__partners{
        margin-bottom: 120px;
    }
    .main-page__form{
        margin-bottom: 60px;
    }
    .main-page__footer{
        background-color: #300402;
    }
    @media (max-width: 600px) {
        .main-page__banner{
            margin-bottom: 30px;
        }
        .main-page__about{
            margin-bottom: 60px;
        }
        .main-page__partners{
            margin-bottom: 0;
        }
    }
</style>