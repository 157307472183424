<template>
    <div class="header">
        <div class="header__inner">
            <img 
                @click="redirectToMain"
                src="../images/logo-white.svg" 
                class="header__logo header__logo_mob"
                v-if="$route.name == 'main' || isActive">

            <img 
                @click="redirectToMain"
                src="../images/logo-brown.svg" 
                class="header__logo header__logo_mob"
                v-else>
            <div 
                :class="{showNav: isActive}"
                class="header__nav-wrapper">
                <div class="header__nav header__nav-left">
                    <router-link 
                        to="/catalog" 
                        class="header__link"
                        @click="close"
                        :class="{color: $route.name !='main'}">Каталог</router-link>
                    <router-link 
                        :to="{path: '/', hash: '#about'}" 
                        class="header__link" 
                        @click="close"
                        :class="{color: $route.name !='main'}">О нас</router-link>
                </div>

                <img 
                    @click="redirectToMain"
                    src="../images/logo-white.svg" 
                    class="header__logo"
                    v-if="$route.name == 'main'">

                <img 
                    @click="redirectToMain"
                    src="../images/logo-brown.svg" 
                    class="header__logo"
                    v-else>

                <div class="header__nav header__nav-right">
                    <router-link 
                        :to="{path: '/', hash: '#partners'}"  
                        class="header__link"
                        @click="close"
                        :class="{color: $route.name !='main'}">Партнеры</router-link>
                    <router-link 
                        :to="{path: '/', hash: '#contacts'}" 
                        class="header__link header__link_contacts"
                        @click="close"
                        :class="{color: $route.name !='main'}">Контакты</router-link>
                    <button 
                        @click="redirectToCart"
                        :class="{altCart: $route.name !='main'}"
                        type="button" 
                        class="header__cart"></button>
                </div>

                <div class="header__mob-contacts mob-contacts">
                    <p class="mob-contacts__tel"><a href="tel:+79122044433" class="mob-contacts__tel-link">+7(912)204-44-33</a> Оптовые продажи в Крыму</p>

                    <p class="mob-contacts__tel"><a href="tel:+79783029271" class="mob-contacts__tel-link">+7(978)302-92-71</a> Оптовые продажи</p>
                    <button 
                        class="mob-contacts__btn btn" 
                        @click="redirectToForm">Заказать звонок</button>
                </div>
            </div>
            <div class="burger-ico" 
                :class="{menuOpen: isActive}"
                @click="toggleBurger">
                    <span 
                        :class="{burgerWhite: $route.name =='main' || isActive}"
                        class="burger-ico__item"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        redirectToMain(){
            this.isActive = false
            document.body.style.overflowY = 'scroll'
            this.$router.push('/')
        },
        redirectToCart(){
            this.isActive = false
            document.body.style.overflowY = 'scroll'
            this.$router.push('/cart')
        },
        redirectToForm(){
            this.isActive = false
            document.body.style.overflowY = 'scroll'
            this.$router.push({path: '/', hash: '#form'})
        },
        toggleBurger(){
            this.isActive = !this.isActive
            if (this.isActive) {
                document.body.style.overflowY = 'hidden'
            }else{
                document.body.style.overflowY = 'scroll'
            }
        },
        close(){
            this.isActive = false
            document.body.style.overflowY = 'scroll'
        }
    },
}
</script>

<style scoped>
    .header__nav-wrapper{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;   
    }
    .header__nav{
        display: flex;
        align-items: center;
    }
    .header__link{
        font-weight: 600;
        font-size: 20px;
        color: #fff;
        margin-right: 20px;
        padding: 15px 35px;
        border-radius: 87px;
        transition: all linear .2s;
        line-height: 100%;
        border: 1px solid transparent;
    }
    .header__link:hover{
        background-color: rgba(255, 255, 255, .3);
    }
    .header__logo{
        cursor: pointer;
    }
    .header__cart{
        height: 50px;
        width: 50px;
        border-radius: 50%;
        background:rgba(255, 255, 255, .3) url(../images/icons/cart-ico-white-2.png) no-repeat;
        background-size: 70%;
        background-position: center;
        transition: all linear .2s;
    }
    .header__cart:hover{
        transform: scale(.95);
    }
    .altCart{
         background:transparent url(../images/icons/cart-ico-black.png) no-repeat;
         background-position: center;
         border: 1px solid #360A04;
    }
    .color{
        color: #360A04;
    }
    .color:hover{
        background: transparent;
        border-color: #360A04;
    }
    .burger-ico{
        display: none;
        align-items: center;
        padding-left: 5px;
    }
    .burger-ico__item{
        position: relative;
        width: 56px;
        height: 2px;
        background-color: #360A04;
    }
    .burger-ico__item::before{
        content: "";
        position: absolute;
        background-color: #360A04;
        width: 56px;
        height: 2px;
        transform: translate(0, 8px);
        transition: all ease .2s;
    }
    .burger-ico__item::after{
        content: "";
        position: absolute;
        background-color: #360A04;
        width: 56px;
        height: 2px;
        transform: translate(0, -8px);
        transition: all ease .2s;
    }
    .burgerWhite{
        background-color: #fff;
    }
    .burgerWhite::before{
        background-color: #fff;
    }
    .burgerWhite::after{
        background-color: #fff;
    }
    .menuOpen span{
        transform: rotate(45deg);
    }
    .menuOpen span::before{
        transform: rotate(-90deg);
    }
    .menuOpen span::after{
        transform: translate(0);
    }
    .header__logo_mob{
        display: none;
    }
    .header__mob-contacts{
        display: none;
    }
    @media (max-width: 768px){
        .header__link{
            font-size: 14px;
            margin-right: 10px;
            padding: 10px 20px;
        }
    }
    @media (max-width: 600px) {
        .header__inner{
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .header__logo{
            display: none;
        }
        .header__logo_mob{
            display: block;
            max-width: 75px;
            z-index: 1500;
        }
        .burger-ico{
            display: flex;
            z-index: 1500;
        } 
        .header__nav-wrapper{
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            background-color: #360A04;
            padding-top: 85px;
            padding-bottom: 30px;
            z-index: 1000;
            justify-content: flex-start;
        }
        .header__nav{
            flex-direction: column;
            align-items: center;
        }
        .header__link{
            margin-right: 0;
            margin-bottom: 20px;
            font-size: 16px;
        }
        .color{
            color: #fff;
        }
        .altCart{
            background:rgba(255, 255, 255, .3) url(../images/icons/cart-ico-white.png) no-repeat;
            background-position: center;
        }
        .showNav{
            display: flex;
        }
        .header__mob-contacts{
            display: flex;
            flex-direction: column;
            margin-top: 90px;
            color: #fff;
            font-weight: 600;
        }
        .mob-contacts__tel{
            margin-bottom: 30px;
        }
        .mob-contacts__tel-link{
            color: #fff;
            text-decoration: underline;
            margin-right: 10px;
        }
        .mob-contacts__btn{
            color: #fff;
            padding: 15px 30px;
            font-weight: 600;
            border-color: #fff;
            align-self: center;
        }
    }
</style>