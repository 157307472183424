<template>
    <div class="age-banner">
        <div class="age-banner__inner">
            <div class="age-banner__form">
                <h1 class="age-banner__heading">Вам есть 18 лет?</h1>
                <div class="age-banner__btn-wrapper">
                    <button 
                        @click="isAdult(false)"
                        type="button" 
                        class="age-banner__btn age-banner__btn_no btn">Нет</button>

                    <button 
                        @click="isAdult(true)"
                        type="button" 
                        class="age-banner__btn age-banner__btn_yes btn">Да</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        isAdult(bool){
            if (bool) {
                localStorage.setItem('isAdult', 'adult')
                this.$emit('checkAge', bool)
            }else{
                this.$router.push('/deny')
            }
        }
    }
}
</script>

<style scoped>
    .age-banner__inner{
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .5);
        z-index: 1000;
        backdrop-filter: blur(10px);
        padding-left: 15px;
        padding-right: 15px;
    }
    .age-banner__form{
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 10px;
        padding: 25px 40px;
        width: 100%;
        max-width: 500px;
        background-color: #fff;
    }
    .age-banner__heading{
        margin-bottom: 35px;
        text-align: center;
    }
    .age-banner__btn-wrapper{
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 260px;
    }
    .age-banner__btn{
        padding: 10px 25px;
    }
</style>