<template>
    <div class="production">
        <h1 class="production__heading heading">ПРОДУКЦИЯ</h1>

        <swiper
            :slides-per-view="1"
            :space-between="20"
            :modules="modules"
            :navigation="{
                        prevEl: '.production-prev',
                        nextEl: '.production-next', 
                    }"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            class="production__slider production-slider"
            >
                <swiper-slide class="production-slider__slide slide">
                    <div class="slide__content">
                        <h3 class="slide__name">Настойка Дикая клюква</h3>
                        <p class="slide__text">Горькая настойка  обладает ярким ароматом и прозрачным красивым красным цветом. Создана на основе сока  сочных ягод клюквы, поэтому имеет освежающий кисловато-острый вкус с характерной легкой горчинкой.</p>
                    </div>

                    <img src="../images/main-products/1.png" class="slide__img">

                    <div class="slide__aside">
                        <div class="slide__vol vol">
                            <p class="vol__heading">Алкоголь</p>
                            <p class="vol__value">40%</p>
                        </div>

                        <div class="slide__size size">
                            <div class="size__icons">
                                <img src="../images/icons/25.png" class="size__img">

                                <img src="../images/icons/50.png" class="size__img">

                                <img src="../images/icons/70.png" class="size__img">
                            </div>
                            <span class="size__text">Ассортимент</span>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="production-slider__slide slide">
                    <div class="slide__content">
                        <h3 class="slide__name">Водка Пшеничная</h3>
                        <p class="slide__text">Истинно белорусский напиток с натуральным, насыщенным пшеничным вкусом. Создан из лучших ингредиентов природы. Он раз и навсегда завоюет ваше доверие. Тот самый вкус, который ни с чем не спутаешь и никогда не забудешь.</p>
                    </div>

                    <img src="../images/main-products/2.png" class="slide__img">

                    <div class="slide__aside">
                        <div class="slide__vol vol">
                            <p class="vol__heading">Алкоголь</p>
                            <p class="vol__value">40%</p>
                        </div>

                        <div class="slide__size size">
                            <div class="size__icons">
                                <img src="../images/icons/25.png" class="size__img">

                                <img src="../images/icons/50.png" class="size__img">

                                <img src="../images/icons/70.png" class="size__img">
                            </div>
                            <span class="size__text">Ассортимент</span>
                        </div>
                    </div>
                </swiper-slide>

                <!-- <swiper-slide class="production-slider__slide slide">
                    <div class="slide__content">
                        <h3 class="slide__name">Настойка Дикая клюква</h3>
                        <p class="slide__text">Горькая настойка  обладает ярким ароматом и прозрачным красивым красным цветом. Создана на основе сока  сочных ягод клюквы, поэтому имеет освежающий кисловато-острый вкус с характерной легкой горчинкой.</p>
                    </div>

                    <img src="../images/main-products/1.png" class="slide__img">

                    <div class="slide__aside">
                        <div class="slide__vol vol">
                            <p class="vol__heading">Алкоголь</p>
                            <p class="vol__value">40%</p>
                        </div>

                        <div class="slide__size size">
                            <div class="size__icons">
                                <img src="../images/icons/25.png" class="size__img">

                                <img src="../images/icons/50.png" class="size__img">

                                <img src="../images/icons/70.png" class="size__img">
                            </div>
                            <span class="size__text">Ассортимент</span>
                        </div>
                    </div>
                </swiper-slide> -->

        </swiper>
        <div class="production__slider-controlls">
            <button 
                    @click="redirectToCatalog"
                    type="button" 
                    class="production__btn btn">Перейти в каталог</button>
            <div class="production-prev"></div>
            <div class="production-next"></div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { ref } from 'vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {
    setup() {
        const onSwiper = (swiper) => {

        };
        const onSlideChange = () => {

        };
        const prev = ref('production-prev');
        const next = ref('production-next');
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y],
            prev,
            next,
        };
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        redirectToCatalog(){
            this.$router.push('/catalog')
        }
    },
}
</script>

<style scoped>
    .production{
        position: relative;
    }
    .production__heading{
        margin-bottom: 70px;
    }
    .production-slider__slide{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #360A04;
    }
    .slide__name{
        font-weight: 900;
        font-size: 24px;
        margin-bottom: 20px;
    }
    .slide__text{
        font-size: 20px;
        max-width: 330px;
    }
    .slide__aside{
        align-self: flex-start;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #360A04;
    }
    .vol__heading{
        font-size: 24px;
        font-weight: 500;
    }
    .vol__value{
        font-family: 'Bebas Neue';
        font-size: 135px;
        font-weight: 700;
        letter-spacing: 0.05em;
    }
    .size__icons{
        display: flex;
        margin-bottom: 20px;
    }
    .size__img{
        margin-right: 15px;
    }
    .size__text{
        font-size: 24px;
        font-weight: 500;
    }
    .production__slider-controlls{
        position: absolute;
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: 250px;
        left: 55%;
        margin-left: -125px;
        bottom: -25px;
        z-index: 100;
    }
    .production-prev{
        height: 80px;
        width: 80px;
        background: url(../images/icons/arrow-ico.svg) no-repeat;
        background-position: center;
        border: 1px solid #360A04;
        border-radius: 50%;
        cursor: pointer;
        transition: all linear .2s;
    }
    .production-prev:hover{
        opacity: .5;
    }
    .production-next{
       height: 80px;
       width: 80px;
       background: url(../images/icons/arrow-ico.svg) no-repeat;
       transform: rotate(180deg);
       background-position: center;
       border: 1px solid #360A04;
       border-radius: 50%;
       cursor: pointer;
       transition: all linear .2s;
    }
    .production-next:hover{
        opacity: .5;
    }
    .production__btn{
        display: none;
    }
    @media (max-width: 992px) {
        .slide__img{
            max-width: 400px;
        }
    }
    @media (max-width: 768px){
        .production-slider__slide{
            flex-direction: column-reverse;
        }
        .slide__aside{
            flex-direction: row-reverse;
            justify-content: space-between;
            min-height: auto;
            width: 100%;
        }
        .slide__text{
            max-width: 500px;
        }
        .production__slider-controlls{
            bottom: -100px;
        }
    }
    @media (max-width: 600px){
        .production__heading{
            margin-bottom: 40px;
        }
        .size__icons{
            margin-bottom: 10px;
        }
        .size__img{
            margin-right: 7px;
            max-width: 28px;
        }
        .vol__heading{
            font-size: 14px;
            margin-bottom: 10px;
        }
        .vol__value{
            font-size: 36px;
        }
        .slide__img{
            margin-top: -50px;
            max-height: 370px;
        }
        .size__text{
            font-size: 14px;
        }
        .slide__name{
            font-size: 16px;
            margin-bottom: 15px;
        }
        .slide__text{
            font-size: 16px;
        }
        .production__slider-controlls{
            max-width: 310px;
            left: 50%;
            margin-left: -155px;
            bottom: -70px;
        }
        .production__btn{
            display: block;
            padding: 10px 20px;
            margin-right: 30px;
        }
        .production-next{
            height: 40px;
            width: 40px;
            background-size: 50%;
        }
        .production-prev{
            height: 40px;
            width: 40px;
            background-size: 50%;
        }
    }
</style>